import moment from "moment";
import 'moment-timezone';
import events from './App';

interface ProjectItem {
    nummer: string;
    kunde: string;
    projekt: string;
    street: string; // New property for street
    city: string;
    name: string;
    phone: string;
    phonetyp: string;
    phone2: string;
    phonetyp2: string;
}
export async function fetchProjects() {
    const response = await fetch('http://192.168.10.2:20801/api/caro2');
    const projectDataFromAPIString = await response.json();

    // Parse the JSON string into an object
    const projectDataFromAPI = JSON.parse(projectDataFromAPIString);

    // Convert eventDataFromAPI to an array of values
    const projectDataArray = Object.values(projectDataFromAPI);

    // Format API data into the structure you need
    const formattedProjectData: ProjectItem[] = [];

    // Iterate over each project
    projectDataArray.forEach((project: any) => {
        const projectDescription = project.PROJECTDESCRIPTION || '';
        const addresses = extractAddresses(projectDescription);
        const phone = (project.FIRSTNAME || "") +
            (project.MIDDLENAMES !== undefined && project.MIDDLENAMES !== null ? " " + project.MIDDLENAMES : "") + " " +
            (project.LASTNAME || "");

        const firstAddress = addresses.length > 0 ? addresses[0] : { street: '', city: '' };

        // Check if the project number is already in formattedProjectData
        const existingEntryIndex = formattedProjectData.findIndex(entry => entry.nummer === project.PROJECTNO);

        if (existingEntryIndex !== -1) {
            // Project number is already in formattedProjectData, update the existing entry
            formattedProjectData[existingEntryIndex].phone2 = project.NORMALIZED;
            formattedProjectData[existingEntryIndex].phonetyp2 = project.TYP;
        } else {
            // Project number is not in formattedProjectData, add a new entry
            formattedProjectData.push({
                nummer: project.PROJECTNO,
                kunde: project.CUSTOMER,
                projekt: projectDescription,
                street: firstAddress.street,
                city: firstAddress.city,
                name: phone,
                phone: project.NORMALIZED ?? "",
                phonetyp: project.TYP ?? "",
                phone2: '',
                phonetyp2: ''
            });
        }
    });

    //console.log("formattedProjectData", formattedProjectData);
    return formattedProjectData;
}



function extractAddresses(text: string): Address[] {
    // German address regex
    const germanAddressRegex = /([\p{Letter}\s�������.-]+\d+[^,]*), (\d{5} [\p{Letter}\s�������.-]+)/ug;
    // Luxembourg address regex
    const luxembourgAddressRegex = /([\p{Letter}\s�������1-9.,-]+)(?:, (L-\d{4})) ([\p{Letter}\s�������.-]+[^,]*)/ug;

    const germanMatches = getMatches(text, germanAddressRegex);
    const luxembourgMatches = getMatches(text, luxembourgAddressRegex);

    const addresses: Address[] = [];

    // Process German addresses
    for (const match of germanMatches) {
        const [, street, city] = match;
        addresses.push({ street: street.trim(), city });
    }

    // Process Luxembourg addresses
    for (const match of luxembourgMatches) {
        const [, street, postalCode, city] = match;
        addresses.push({ street: `${street.trim()}`, city: `${postalCode} ${city}` });
    }

    // If there is a comma-separated address in the project description
    if (text.includes(',')) {
        const parts = text.split(',').map(part => part.trim());

        // Process each part to identify street and city
        for (const part of parts) {
            // Improved regex to handle street and city extraction
            const addressMatch = /([\p{Letter}\s�������.,-]+), (L-\d{4}) ([\p{Letter}\s�������.-]+[^,]*)/ug.exec(part);

            if (addressMatch) {
                const [, street, postalCode, city] = addressMatch;
                addresses.push({ street: `${street}`, city: `${postalCode} ${city}` });
            }
        }
    }

    return addresses;
}

function getMatches(text: string, regex: RegExp): RegExpMatchArray[] {
    const matches: RegExpMatchArray[] = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
        matches.push(match);
    }
    return matches;
}


export async function fetchEmployees() {
    const response = await fetch('http://192.168.10.2:20801/api/caro');
    //console.log(response);
    const employeeDataFromAPIString = await response.json();  // Assuming the response is a string
    //console.log(eventDataFromAPIString);

    // Parse the JSON string into an object
    const employeeDataFromAPI = JSON.parse(employeeDataFromAPIString);

    // Convert eventDataFromAPI to an array of values
    const employeeDataArray = Object.values(employeeDataFromAPI);

    // Format API data into the structure you need
    const formattedEmployeeData: EmployeeItem[] = employeeDataArray.map((employee: any) => ({
        name: employee.PERSONNO
    }));
    //console.log("events Zeile 25: projectDataArray", formattedProjectData);
    return formattedEmployeeData;

};


export async function fetchEventData() {
    const response = await fetch('http://192.168.10.2:20801/api/mb');
    //console.log(response);
    const eventDataFromAPIString = await response.json();  // Assuming the response is a string
    //console.log(eventDataFromAPIString);

    // Parse the JSON string into an object
    const eventDataFromAPI = JSON.parse(eventDataFromAPIString);

    // Convert eventDataFromAPI to an array of values
    const eventDataArray = Object.values(eventDataFromAPI);
    //console.log("events Zeile 40: eventDataArray", eventDataArray);

    // Format API data into the structure you need
    const formattedEventData: EventItem[] = eventDataArray.map((event: any) => {
        // Replace '49' with '+49' if the phone number starts with '49'
        const formattedTelefon = event.telefon.replace(/~/g, '&').split('_').map((phoneNumber: string) => phoneNumber.trim().startsWith('49') ? '+' + phoneNumber.trim() : phoneNumber);
        //console.log("formattedTelefon: ", formattedTelefon)
        return {
            start: new Date(event.start),
            end: new Date(event.ende),
            id: event.eventID,
            resourceId: parseInt(event.status, 10),
            status: event.status.trim(),
            title: event.bauvorhaben,
            mitarbeiter: event.mitarbeiter.replace(/:/g, ' ').replace(/;/g, '<br>'),
            resource: event.mitarbeiter,
            ort: event.ort,
            strasse: event.strasse,
            hausnummer: event.hausnummer,
            zusatz: event.adresszusatz,
            isDraggable: event.draggable,
            fahrzeuge: event.fahrzeuge.replace(/:/g, ' ').replace(/;/g, '<br>'),
            bauherr: event.bauherr,
            notiz: event.notiz.replace(/~/g, '<br>').replace(/<hashtag>/g, '#').replace(/<und>/g, '&').replace(/<apos>/g, "'").replace(/<plus>/g, '+'),
            frei1: event.frei1,
            telefon: formattedTelefon, // Assign the formatted telefon array
        };
    });


    //console.log("formattedEventData: ", formattedEventData);
    return formattedEventData;
};

export const resizeEventToDB = async (event, start, end, appointmentId) => {

    const { status } = event || { status: 6 };
    const { title } = event || { title: "keines" };
    const { mitarbeiter } = event || { mitarbeiter: "keiner" }; // Assuming mitarbeiter is a string
    const { notiz } = event || { notiz: "" };
    const { ort } = event || { ort: "keiner" };
    const { strasse } = event || { strasse: "keine" };
    const { hausnummer } = event || { hausnummer: "keine" };
    const { zusatz } = event || { adresszusatz: "keine" };
    const { isDraggable } = event || { isDraggable: 1 };
    const { fahrzeuge } = event || { fahrzeuge: "" };
    const { bauherr } = event || { bauherr: "" };
    const { frei1 } = event || { frei1: "" };
    let { telefon } = event || { telefon: "" };
    //console.log("ende: ", end);
    telefon = telefon.join('_').replace(/&/g, '~');
    //console.log("hausnummer: ", hausnummer);
    const formattedMitarbeiter = mitarbeiter.replace(/\s+/g, ':');
    const formattedFahrzeuge = fahrzeuge.replace(/\s+/g, ':');
    const formattedNote = notiz.replace(/\n/g, '~').replace(/#/g, '<hashtag>').replace(/&/g, '<und>').replace(/'/g, '<apos>').replace(/\+/g, '<plus>');
    const apiUrl = `http://192.168.10.2:20801/api/update/${appointmentId}?start=${moment(start).toISOString()}&ende=${moment(end).toISOString()}&status=${status}&bauvorhaben=${title}&mitarbeiter=${formattedMitarbeiter}&ort=${ort}&strasse=${strasse}&hausnummer=${hausnummer}&adresszusatz=${zusatz}&draggable=${isDraggable}&fahrzeuge=${formattedFahrzeuge}&bauherr=${bauherr}&notiz=${formattedNote}&frei1=${frei1}&telefon=${telefon}`;
    console.log("resizeEventToDB, apiUrl:", apiUrl)
    try {
        const response = await fetch(apiUrl, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
        });

        if (!response.ok) {
            console.error("Error:", response);
            window.alert("Fehler!!\n \n\n" + response.statusText + "\n" + response.url);
        } else {
            const result = await response.json();
            console.log("Success:", result);
        }
    } catch (error) {
        console.error("Fetch error:", error);
    }
};



export const newEventToDB = async (event) => {

    const apiUrl = `http://192.168.10.2:20801/api/mb`;

    let drag;

    if (event.draggable === true) { drag = "1" }
    else if (event.draggable === false) { drag = "0" }

    if (moment(event.end).isBefore(moment(event.start))) {
        // If it is, add 12 hours to event.end
        event.end = moment(event.end).add(12, 'hours').toDate();
    }

    const eventData = {
        bauvorhaben: event.title,
        allDay: event.isAllDay,
        Start: moment(event.start).format('YYYY-MM-DDTHH:mm:ss.000[Z]'),
        end: moment(event.end).format('YYYY-MM-DDTHH:mm:ss.000[Z]'),
        status: event.status,
        mitarbeiter: event.mitarbeiter,
        ort: event.ort,
        strasse: event.strasse,
        hausnummer: event.hausnummer,
        addresszusatz: event.zusatz,
        draggable: drag,
        fahrzeuge: event.fahrzeuge,
        bauherr: event.bauherr,
        notiz: event.notiz,
        frei1: event.frei1,
        telefon: '____'        
    };

    //console.log("new eventData", eventData);
    //console.log("new eventData start", eventData.Start);
    //console.log("new eventData ende", eventData.end);

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(eventData),
        });

        if (!response.ok) {
            console.error("Error:", response.statusText);
        } else {
            const result = Object.values(JSON.parse(await response.json()));

            const eventIDs = result.map(item => item.eventID);

            // Find the maximum eventID
            const maxEventID = Math.max(...eventIDs);

            console.log("Success:", maxEventID);
            return maxEventID;
        }
    } catch (error) {
        console.error("Fetch error:", error);
    }

};

export const deleteEventFromDB = async (eventID) => {


    const apiUrl = `http://192.168.10.2:20801/api/delete/${eventID}`;

    try {
        const response = await fetch(apiUrl, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
        });

        if (!response.ok) {
            console.error("Error:", response.statusText);
        } else {
            const result = await response.json();
            console.log("Success:", response);
        }
    } catch (error) {
        console.error("Fetch error:", error);
    }

};
