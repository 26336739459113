import React, { useState } from 'react';
import { deleteEventFromDB, resizeEventToDB } from "./events.tsx";
import moment from "moment";
import 'moment-timezone';


const PopupForm = ({ projects, employees, verfMitarbeiter, fahrzeuge, verfFahrzeuge, event, onClose, bearbeiten, setBearbeiten }) => {

    const [searchQuery, setSearchQuery] = useState('');

    //console.log("event: ", event)
    const [formData, setFormData] = useState({
        //resourceId: event.title,
        //mitarbeiter: [],
        mitarbeiter: event.mitarbeiter.split("<br>").flatMap(employee => employee.split(";").map(entry => entry.trim())),
        fahrzeuge: event.fahrzeuge.split("<br>").flatMap(employee => employee.split(";").map(entry => entry.trim())),
        ort: event.ort,
        strasse: event.strasse,
        hausnummer: event.hausnummer,
        start: moment(event.start).format("YYYY-MM-DDTHH:mm"),
        ende: moment(event.end).format("YYYY-MM-DDTHH:mm"),
        resource: event.resource,
        status: event.status,
        bauherr: event.bauherr,
        projectnumber: event.title || '',
        phone: event.telefon[0] || '',
        phonetyp: event.telefon[1] || '',
        phone2: event.telefon[2] || '',
        phonetyp2: event.telefon[3] || '',
        ansprechpartner: event.telefon[4] || '',
        notiz: event.notiz || '', 
    });

    const isInBoth = (item) => verfFahrzeuge.includes(item);
    const isInBoth2 = (item) => verfMitarbeiter.map(employee => employee.name).includes(item);

    // Map through the fahrzeuge array and encapsulate items if they exist in verfFahrzeuge
    const formattedFahrzeuge = fahrzeuge.map((item) => ({
        label: !isInBoth(item) && !formData.fahrzeuge.includes(item) ? `(${item})` : item,
        value: item
    }));
    const formattedEmployees = employees.map(name => name.name).map((item) => ({
        label: !isInBoth2(item) && !formData.mitarbeiter.includes(item) ? `(${item})`: item,
        name: item
    }));

    //console.log("employees", employees.map((name) => name.name));
    //console.log("formData.mitarbeiter", formData.mitarbeiter);
    //console.log("verfMitarbeiter", verfMitarbeiter.map(employee => employee.name));
    //console.log("formattedEmployees", formattedEmployees);
    //console.log("formattedFahrzeuge", formattedFahrzeuge);
    //console.log("formData.fahrzeuge", formData.fahrzeuge);


    // Filtering projects based on the search query
    const filteredProjects = projects.filter(project =>
        (project.nummer && project.nummer.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (project.projekt && project.projekt.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (project.kunde && project.kunde.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (project.street && project.street.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (project.city && project.city.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (project.name && project.name.toLowerCase().includes(searchQuery.toLowerCase()))
    );




    const handleChange = (name, value) => {
        // Update the form data when a field changes
        setFormData({
            ...formData,
            [name]: value,
        });
    };
   
    const handleSubmit = async () => {
        if (bearbeiten) {
            try {
                const start = new Date(formData.start);
                const end = new Date(formData.ende);

                // Check if start is before end
                if (start >= end) {
                    // If start is not before end, display a warning
                    window.alert("Startzeit muss vor dem Ende des Termins liegen!");
                    return; // Exit the function without saving
                }

                const telefon = [];
                telefon[0] = formData.phone || '';
                telefon[1] = formData.phonetyp || '';
                telefon[2] = formData.phone2 || '';
                telefon[3] = formData.phonetyp2 || '';
                telefon[4] = formData.ansprechpartner || '';

                // Create a new object with the updated information from the form fields
                const updatedEvent = {
                    ...event,
                    title: formData.projectnumber,
                    mitarbeiter: formData.mitarbeiter.join('; '),
                    fahrzeuge: formData.fahrzeuge.join('; '),
                    ort: formData.ort,
                    strasse: formData.strasse,
                    start: new Date(formData.start),
                    end: new Date(formData.ende),
                    hausnummer: '',
                    resource: formData.resource,
                    status: formData.status,
                    bauherr: formData.bauherr || '',
                    projectnumber: formData.projectnumber || '',
                    notiz: formData.notiz,
                    telefon: telefon, // Include notiz in the updated event
                    // ... include other fields as needed
                };

                // Use the resizeEventToDB function to save the updated event
                await resizeEventToDB(updatedEvent, updatedEvent.start, updatedEvent.end, event.id);

                // Optionally, you can perform additional actions after the successful save
                console.log("handlesubmit: start: ", updatedEvent.start);
                console.log("Gespeichert!");

                // Close the form
                //console.log(updatedEvent.fahrzeuge)
                onClose("save", updatedEvent);
            } catch (error) {
                console.error("handleSubmit Error saving changes:", error);
            }
        }
        else {
            window.alert("Bearbeiten ist nicht aktiviert! \nMit [Strg]+[<] aktivieren.")
        }
    };

    const onDelete = async () => {
        // Display a confirmation dialog
        const shouldDelete = window.confirm("Termin wirklich löschen?");

        // Check if the user confirmed the deletion
        if (shouldDelete) {
            console.log("eventId: ", event.id);

            // Perform the deletion from the database
            await deleteEventFromDB(event.id);

            // Close the PopupForm and pass the "del" action and the event
            onClose("del", event);
        }
    };

    const onCancel = () => {
        onClose(false, event);
    };

    //const { exec } = require('child_process');


    //const onData = () => {
    //    // Use exec to open the file explorer at the specified path
    //    exec('explorer \\\\SERVER\\Caro\\Fremddateien\\Kunden', (err) => {
    //        if (err) {
    //            console.error("Error opening file explorer:", err);
    //        }
    //    });

    //};

    // Update the form data when a project is selected
    const handleProjectSelect = (selectedProject) => {
        setFormData({
            //resourceId: selectedProject.nummer,
            mitarbeiter: formData.mitarbeiter || [],
            fahrzeuge: formData.fahrzeuge || [],
            ort: selectedProject.city || '',
            strasse: selectedProject.street || '',
            hausnummer: selectedProject.hausnummer || '',
            start: formData.start,
            ende: formData.ende,
            resource: selectedProject.mitarbeiter || '',
            status: formData.status,
            bauherr: selectedProject.kunde || '', // Set this to the default value or leave it empty
            projectnumber: selectedProject.nummer || '',
            phone: selectedProject.phone || '',
            phonetyp: selectedProject.phonetyp || '',
            phone2: selectedProject.phone2 || '',
            phonetyp2: selectedProject.phonetyp2 || '',
            ansprechpartner: selectedProject.name || '',
            notiz: formData.notiz || '', // Update projectnumber on project select
            // ... other fields as needed
        });
    };

    return (
        <div
            className="popup-form"
            onKeyDown={(e) => {
                if (e.key === 'Escape') {
                    onCancel();
                } else if (e.key === '<' && e.ctrlKey) {
                    setBearbeiten();
                    //console.log(bearbeiten);
                }
            }}
            style={{
                padding: '20px',
                border: '2px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#fff',
                margin: 'auto',
                display: 'grid',
                gridTemplateColumns: '2fr 1fr',
                gap: '10px'
            }}
        >

            {/* Search Field */}
            <input
                type="text"
                placeholder="Projekte Filtern"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                //onKeyDown={(e) => { if (e.key === 'Enter') handleProjectSelect(filteredProjects.find(project => project.nummer === e.target.value)) }}
                style={{ gridArea: '1 / 1 / 2 / 4' }}
            />

            {/* Projects Dropdown */}
            <select
                onChange={(e) => handleProjectSelect(filteredProjects.find(project => project.nummer === e.target.value))}
                style={{ gridArea: '2 / 1 / 3 / 4', marginBottom: '10px' }}
                value={formData.projectnumber}
                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(); }}
            >
                {filteredProjects.map(project => (
                    <option value={project.nummer} key={project.nummer}
                        onClick={() => handleProjectSelect(project)}>
                        {project.nummer} - {project.kunde} - {project.projekt}
                    </option>
                ))}
            </select>

            {/* Project Number */}
            <input
                type="text"
                value={formData.projectnumber}
                onChange={(e) => handleChange('projectnumber', e.target.value)}
                placeholder="Projekt"
                style={{ gridArea: '3 / 1 / 4 / 2' }}
                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(); }}
                autoFocus
            />

            {/* Other Fields */}
            <input
                type="text"
                value={formData.bauherr}
                onChange={(e) => handleChange('bauherr', e.target.value)}
                placeholder="Bauherr"
                style={{ gridArea: '4 / 1 / 5 / 2' }}
                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(); }}
            />
            <input
                type="text"
                value={formData.ort}
                onChange={(e) => handleChange('ort', e.target.value)}
                placeholder="Ort"
                style={{ gridArea: '5 / 1 / 6 / 2' }}
                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(); }}
            />
            <input
                type="text"
                value={formData.strasse}
                onChange={(e) => handleChange('strasse', e.target.value)}
                placeholder="Strasse"
                style={{ gridArea: '6 / 1 / 7 / 2' }}
                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(); }}
            />
            {/*--------------------------*/}
            <input
                type="text"
                value={formData.ansprechpartner}
                onChange={(e) => handleChange('ansprechpartner', e.target.value)}
                placeholder="Ansprechpartner"
                style={{ gridArea: '7 / 1 / 8 / 2' }}
                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(); }}
            />
            <input
                type="tel"
                value={formData.phone}
                onChange={(e) => handleChange('phone', e.target.value)}
                placeholder="Telefon 1"
                style={{ gridArea: '8 / 1 / 9 / 2' }}
                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(); }}
            />
            <input
                type="tel"
                value={formData.phone2}
                onChange={(e) => handleChange('phone2', e.target.value)}
                placeholder="Telefon 2"
                style={{ gridArea: '9 / 1 / 10 / 2' }}
                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(); }}
            />
            {/*--------------------------*/}
            {/* Kolonne Dropdown */}
            <select
                value={formData.status}
                onChange={(e) => handleChange('status', e.target.value)}
                placeholder="Kolonne"
                style={{
                    gridArea: '10 / 1 / 11 / 2',
                    backgroundColor: 'white',
                    border: '1px solid #999' }}
                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(); }}
            >
                {[
                    { index: 1, item: "Dämmung" },
                    { index: 2, item: "Thermowhite" },
                    { index: 3, item: "Zementestrich 1" },
                    { index: 4, item: "Zementestrich 2" },
                    { index: 5, item: "Fließestrich" },
                    { index: 7, item: "Zusatzkolonne" },
                    { index: 8, item: "Meterriss" },
                    { index: 10, item: "Finn" },
                    { index: 6, item: "Sonstiges" }
                ].map(({ index, item }) => (
                    <option key={index} value={index}>
                        {item}
                    </option>
                ))}
            </select>

            <input
                type="datetime-local"
                value={formData.start}
                onChange={(e) => handleChange('start', e.target.value)}
                placeholder="Start"
                style={{ gridArea: '11 / 1 / 12 / 2' }}
                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(); }}
            />
            <input
                type="datetime-local"
                value={formData.ende}
                onChange={(e) => handleChange('ende', e.target.value)}
                placeholder="Ende"
                style={{ gridArea: '12 / 1 / 13 / 2' }}
                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(); }}
            />

            {/* Employees Multi-Select */}
            <select
                multiple
                value={formData.mitarbeiter}  // Set the value attribute to control the selected options
                onChange={(e) => {
                    handleChange('mitarbeiter', Array.from(e.target.selectedOptions, option => option.value)); //.join('; ')
                }}
                style={{ gridArea: '3 / 3 / 33 / 3',  }}
            >
                {formattedEmployees.map(employee => (
                    <option value={employee.name} key={employee.label}
                        style={{ color: employee.label.startsWith('(') ? 'grey' : 'inherit' }}
                    >
                        {employee.label}
                    </option>
                ))}
            </select>

            {/* Fahrzeuge Multi-Select */}
            <select
                multiple
                value={formData.fahrzeuge}  // Set the value attribute to control the selected options
                onChange={(e) => {
                    handleChange('fahrzeuge', Array.from(e.target.selectedOptions, option => option.value)); //.join('; ')
                }}
                style={{ gridArea: '3 / 2 / 13 / 2' }}
            >
                {formattedFahrzeuge.map(fahrzeug => (
                    <option
                        value={fahrzeug.value}
                        key={fahrzeug.label}
                        style={{ color: fahrzeug.label.startsWith('(') ? 'grey' : 'inherit' }}
                    >
                        {fahrzeug.label}
                    </option>
                ))}
            </select>

            {/* Notiz */}
            <textarea
                value={formData.notiz.replace('<br>' ,'\n')}
                onChange={(e) => handleChange('notiz', e.target.value)}
                placeholder="Notiz"
                style={{ gridArea: '13 / 1 / 33 / 3' }}
            />

            {/* Submit, Cancel, Delete Buttons */}
            <div style={{ gridArea: '34 / 1 / 33 / 4', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                {bearbeiten && (
                    <>
                        <button onClick={handleSubmit}>Speichern</button>
                        <button onClick={onDelete}>Löschen</button>
                        {/*<button onClick={onData}>Dateiablage</button>*/}
                    </>
                )}
                <button onClick={onCancel}>Abbrechen</button>
            </div>
        </div>
    );
};

export default PopupForm;