// App.tsx
import React, { useCallback } from 'react';
import { fetchEventData, fetchEmployees, fetchProjects, resizeEventToDB, newEventToDB } from "./events.tsx";
import { Calendar as BigCalendar, Day, momentLocalizer, Views, Navigate, WorkWeek } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.scss";
import moment from "moment";
import 'moment/locale/de';
import PopupForm from './Popup.tsx';
import SearchPopup from './SearchPopup.tsx';
//import { AgendaView } from './Agenda.tsx';


const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(BigCalendar);

let Projects;
let Employees;

let bearbeiten = false;

function fetch() {
    fetchProjects()
        .then(projects => {
            Projects = projects;
        })
        .catch(error => {
            console.error("Fehler beim abrufen der Projekte:", error);
        });

    fetchEmployees()
        .then(employees => {
            Employees = employees;
        })
        .catch(error => {
            console.error("Fehler beim abrufen der Mitarbeiter:", error);
        });
};

fetch();

const formatName = (Nname, count) => `${Nname} ID ${count}`;


export var events = await fetchEventData();

const Fahrzeuge = [
    "BKS-PM-333 MAN Andreas",
    "BKS-PM-20 Pumpe Andreas",
    "BKS-PM-111 MAN Heinz",
    "BKS-PM-40 Pumpe Heinz",
    "WIL-PM-25 Auslegebus",
    "WIL-BM-460 Fließestrichbus",
    "BKS-PM-3 Transit, klein",
    "BKS-PM-300 Hilux",
    "WIL-PM-101 Transit neu",
    "WIL-BM-555 Anhänger 1,5T",
    "BKS-PM-11 Anhänger, 2 achsig",
    "BKS-PM-44 Pumpe Fließestrich",
    "WIL-PM-335 Pumpe, gelb",
];

class Dnd extends React.Component {
    state: any;
    constructor(props) {
        super(props);
        this.state = {
            events: events,
            eventsBackup: events,
            draggedEvent: null,
            counters: {
                'Kolonne 1': 0,
                'Kolonne 2': 0,
                'Kolonne 3': 0
            },
            currentView: "day",
            currentDate: Date.now(),
            currentEvents: [],
            showPopupForm: false,
            nonUniqueEmployees: [],
            verfFahrzeuge: [],
            isSearchModalOpen: true,
            searchQuery: "",
            searchResults: [],
            mapsEvents: [],
            meterriss: false
        };

        // Bind the onClose function to the class
        this.onClose = this.onClose.bind(this);
    };

    myresources(date) {
        if (this.state.meterriss == false) {
            console.log("this.state.mapsEvents[8] :", this.state.mapsEvents[8])
            return [
                { id: 1, title: <a href={`https://www.google.de/maps/dir/Kirchstra%C3%9Fe+20,+54426+Heidenburg/${this.state.mapsEvents[1]}/Kirchstra%C3%9Fe+20,+54426+Heidenburg/am=t/`} target="_blank" rel="noopener noreferrer">Dämmung</a>, backgroundColor: '#ff0000' },
                { id: 2, title: <a href={`https://www.google.de/maps/dir/Europa-Allee+38,+54343+Foehren/${this.state.mapsEvents[2]}/Europa-Allee+38,+54343+Foehren/am=t/`} target="_blank" rel="noopener noreferrer">Thermowhite</a> },
                { id: 3, title: <a href={`https://www.google.de/maps/dir/Kirchstra%C3%9Fe+20,+54426+Heidenburg/${this.state.mapsEvents[3]}/Kirchstra%C3%9Fe+20,+54426+Heidenburg/am=t/`} target="_blank" rel="noopener noreferrer">Zementestrich 1</a> },
                { id: 4, title: <a href={`https://www.google.de/maps/dir/Kirchstra%C3%9Fe+20,+54426+Heidenburg/${this.state.mapsEvents[4]}/Kirchstra%C3%9Fe+20,+54426+Heidenburg/am=t/`} target="_blank" rel="noopener noreferrer">Zementestrich 2</a> },
                { id: 5, title: <a href={`https://www.google.de/maps/dir/Kirchstra%C3%9Fe+20,+54426+Heidenburg/${this.state.mapsEvents[5]}/Kirchstra%C3%9Fe+20,+54426+Heidenburg/am=t/`} target="_blank" rel="noopener noreferrer">Fließestrich</a> },
                { id: 7, title: <a href={`https://www.google.de/maps/dir/Kirchstra%C3%9Fe+20,+54426+Heidenburg/${this.state.mapsEvents[7]}/Kirchstra%C3%9Fe+20,+54426+Heidenburg/am=t/`} target="_blank" rel="noopener noreferrer">Zusatzkolonne</a> },
                { id: 8, title: <><a href={`https://www.google.de/maps/dir/Kirchstra%C3%9Fe+20,+54426+Heidenburg/${this.state.mapsEvents[8]}/Kirchstra%C3%9Fe+20,+54426+Heidenburg/am=t/`} target="_blank" rel="noopener noreferrer">Meterriss</a> {this.state.mapsEvents[9] && <a href={`https://www.google.de/maps/dir/Kirchstra%C3%9Fe+20,+54426+Heidenburg/${this.state.mapsEvents[9]}/Kirchstra%C3%9Fe+20,+54426+Heidenburg/am=t/`} target="_blank" rel="noopener noreferrer">/2</a>}</> },
                { id: 10, title: <a href={`https://www.google.de/maps/dir/Kirchstra%C3%9Fe+20,+54426+Heidenburg/${this.state.mapsEvents[10]}/Kirchstra%C3%9Fe+20,+54426+Heidenburg/am=t/`} target="_blank" rel="noopener noreferrer">Finn</a> },
                { id: 6, title: <a href={`https://www.google.de/maps/dir/Kirchstra%C3%9Fe+20,+54426+Heidenburg/${this.state.mapsEvents[6]}/Kirchstra%C3%9Fe+20,+54426+Heidenburg/am=t/`} target="_blank" rel="noopener noreferrer">Sonstiges</a> }
            ];
        }
        else {
            return [
                { id: 8, title: <a href={`https://www.google.de/maps/dir/Kirchstra%C3%9Fe+20,+54426+Heidenburg/${this.state.mapsEvents[8]}/Kirchstra%C3%9Fe+20,+54426+Heidenburg/am=t/`} target="_blank" rel="noopener noreferrer">Meterriss</a> },
            ];
        }
    }


    async areEmployeesAlreadyUsed(event) {

        const sameDateEvents = await this.sameDateEvents(this.state.currentDate);

        // Filter events in currentEvents for the same day and status
        const eventsOnSameDayAndStatus = sameDateEvents.filter((e) => {
            return (
                e.resourceId !== event.resourceId
            );
        });
        //console.log("eventsOnSameDayAndStatus", eventsOnSameDayAndStatus)

        // Check if employees are already used in other events on the same day and status
        const eventEmployees = event.mitarbeiter.split("<br>").flatMap(employee => employee.split(";").map(entry => entry.trim()));
        let commonEmployees = [];

        for (const e of eventsOnSameDayAndStatus) {
            const otherEventEmployees = e.mitarbeiter.split("<br>").flatMap(employee => employee.split(";").map(entry => entry.trim()));

            //console.log("e.status - event.status", e.status, event.status);
            //console.log("otherEventEmployees", otherEventEmployees);
            //console.log("eventEmployees", eventEmployees);

            commonEmployees = commonEmployees.concat(
                eventEmployees.filter((employee) =>
                    otherEventEmployees.includes(employee)
                )
            );
        }
        commonEmployees = Array.from(new Set(commonEmployees));
        if (commonEmployees.length > 0) {

            window.alert("Achtung! \nFolgende(r) Mitarbeiter bereits eingeteilt: \n\n" + commonEmployees.join("\n"));
            return true; // Employees are already used in another event
        }
        
        return false; // Employees are not used in any other event on the same day and status
    }


    handleSearch = (query) => {
        // Split the query into individual words
        const searchWords = query.toLowerCase().split(' ');

        // Filter events based on search query
        const searchResults = this.state.events.filter((event) =>
            searchWords.every(word =>
                `${moment(event.start).format('DDMMYYYY')} ${event.title} ${event.bauherr} ${event.strasse} ${event.ort} ${event.notiz} ${event.mitarbeiter} ${statusMapping[event.status]}`
                    .toLowerCase()
                    .includes(word)
            )
        );

        this.setState({
            searchQuery: query,
            searchResults: searchResults,
        });
     };

    handleDateChange = (selectedDate) => {
        // Check if the selected date is a valid date
        const isValidDate = selectedDate instanceof Date && !isNaN(selectedDate);

        // Check if the date is in the expected format (YYYY-MM-DD)
        const isValidFormat = /^\d{4}-\d{2}-\d{2}$/.test(selectedDate.toISOString().split('T')[0]);

        if (isValidDate && isValidFormat) {
            // Valid date
            this.setState({
                currentDate: selectedDate,
            });
        } else {
            // Invalid date format or value, substitute with today's date
            const today = new Date();
            this.setState({
                currentDate: today,
            });
        }

        // Call handleViewChange with the selected date and current view
        this.handleViewChange(selectedDate, this.state.currentView);
    };

    onClose = (del, event) => {

        //console.log("events: ", events)

        if (del === "del") {
            this.deleteEvent(event.id);
        }
        if (del === "save") {
            console.log("onClose: event.status: ", event.status);
            console.log("onClose: currentEvents: ", this.state.currentEvents); //mitarbeiter aus currentevents mit status= event.status auslesen, dann warnen 

            this.areEmployeesAlreadyUsed(event)
            this.editEvent(event)
            this.sameDateEvents(this.state.currentDate)
            //console.log("save")
        }
        else {
            this.setState({
                events: events
            });
        }
        this.setState({ showPopupForm: false });
    };

    deleteEvent(eventId) {
        // Filter out the event with the specified eventId
        const updatedEvents = events.filter((evnt) => evnt.id !== eventId);

        //console.log("eventId: ", eventId)
        //console.log("events: ", this.state.events)
        //console.log("updatedEvents: ", updatedEvents)

        // Update the state with the filtered events
        this.setState({
            events: updatedEvents,
            eventsBackup: updatedEvents
        });
        events = updatedEvents
        this.sameDateEvents(this.state.currentDate)
    };

    editEvent(event) {
        // Find the index of the event with the specified eventId
        const eventIndex = events.findIndex((evnt) => evnt.id === event.id);
        event.mitarbeiter = event.mitarbeiter.replace(/; /g, '<br>');
        event.fahrzeuge = event.fahrzeuge.replace(/; /g, '<br>');
        event.notiz = event.notiz.replace(/\n/g, '<br>');
        event.resourceId = parseInt(event.status);
        //console.log("editEvent, eventId: ", event.id)
        if (eventIndex>-1) {
            // Update the event at the found index with the edited data
            events[eventIndex] = event;
            // Update the state with the edited events
            this.setState({
                events: [...events], // Ensure a new array reference to trigger re-render
                eventsBackup: [...events]
            });

            console.log("Event erfolgreich bearbeitet!");

        } else {
            console.error("Event nicht gefunden.", event.id);
            console.error("eventIndex: ", eventIndex);
            console.error("vents:", events);
        }
        this.sameDateEvents(this.state.currentDate)
        //console.log("Edit event: ", event.id);
        //console.log("eventIndex: ", eventIndex);
        //console.log("vents:", events);
    };


    handleDragStart = (Nname) => {
        this.setState({ draggedEvent: Nname });
    };

    customOnDragOver = (event) => {
        // check for undroppable is specific to this example
        // and not part of API. This just demonstrates that
        // onDragOver can optionally be passed to conditionally
        // allow draggable items to be dropped on cal, based on
        // whether event.preventDefault is called
        if (this.state.draggedEvent !== "undroppable") {
            console.log("customOnDragOver");
            event.preventDefault();
        }
    };

    onDropFromOutside = ({ start, end, allDay, resourceId }) => {
        const { draggedEvent, counters } = this.state;
        const event = {
            title: formatName(draggedEvent, counters[draggedEvent]),
            start: start,
            end: end,
            isAllDay: allDay,
            resourceId: resourceId,
            status: resourceId,
        };
        const updatedCounters = {
            ...counters,
            [draggedEvent]: counters[draggedEvent] + 1
        };
        this.setState({ draggedEvent: null, counters: updatedCounters });
        this.newEvent(event);
    };




    moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot, resourceId }) => {
        const { events } = this.state;

        const idx = events.indexOf(event);
        let allDay = event.allDay;

        if (!event.allDay && droppedOnAllDaySlot) {
            allDay = true;
        } else if (event.allDay && !droppedOnAllDaySlot) {
            allDay = false;
        }

        const updatedEvent = { ...event, start, end, allDay, resourceId };

        if (this.state.currentView === "day") {
            updatedEvent.resourceId = resourceId;
            updatedEvent.status = resourceId;
        } else {
            updatedEvent.resourceId = event.resourceId;
            updatedEvent.status = event.resourceId;
        }
        this.areEmployeesAlreadyUsed(updatedEvent) 

        //console.log("moveEvent updatedEvent.resourceId", updatedEvent.resourceId);
        //console.log("moveEvent idx", idx);
        const nextEvents = [...events];
        nextEvents.splice(idx, 1, updatedEvent);

        this.setState({
            events: nextEvents
        }, () => {
            // Call resizeEventToDB after the state has been updated
            resizeEventToDB(updatedEvent, start, end, updatedEvent.id);
        });
        // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
    };


    resizeEvent = ({ event, start, end }) => {
        const { events } = this.state;

        const nextEvents = events.map((existingEvent) => {
            return existingEvent.id === event.id
                ? { ...existingEvent, start, end }
                : existingEvent;
        });

        this.setState({
            events: nextEvents
        });

        resizeEventToDB(event, start, end, event.id);

        // alert(`${event.title} was resized to ${start}-${end}`)
    };

    handleSelectEvent = (event) => { // Add this function to handle view changes

        //PopupForm(Projects, Employees, event);
        this.setState({ showPopupForm: true, event: event });
        //console.log("Projects", Projects);
        //fetch();
    };


    eventStyleGetter = (event) => {
        let backgroundColor = '#3174ad'; // default color
        let color = 'white';
        switch (event.resourceId) {
            case 1:
                backgroundColor = '#fffffe';
                color = 'black';
                break;
            case 2:
                backgroundColor = '#eeeeee';
                color = 'black';
                break;
            case 3:
                backgroundColor = '#cccccc';
                color = 'black';
                break;
            case 4:
                backgroundColor = '#aaaaaa';
                color = 'black';
                break;
            case 5:
                backgroundColor = '#666666';
                break;
            case 7:
                backgroundColor = '#222222';
                break;
            case 8:
                if (event.mitarbeiter === "Alt, Matthias") {
                    backgroundColor = '#111111';
                    break;
                }
                else {
                    backgroundColor = '#0f3f70';
                    break;
                }
            case 6:
                backgroundColor = '#123456';
                break;
            case 10:
                backgroundColor = '#000000';
                break;
            default:
                break;
        }

        const style = {
            backgroundColor: backgroundColor,
            borderRadius: '4px',
            border: '1px solid #000000',
            opacity: 0.9,
            color: color,
            //display: 'block'
        };
        return {
            style: style
        };
    };

    newEvent = async (event) => {
            
        let hour = {
            id: event.id,
            title: event.title ?? "",
            allDay: event.isAllDay ?? false,
            start: event.start,
            end: event.end,
            status: event.resourceId,
            resourceId: event.resourceId,
            resource: "",
            mitarbeiter: "",
            ort: "",
            strasse: "",
            hausnummer: "",
            zusatz: "",
            draggable: true,
            isDraggable: true,
            fahrzeuge: "",
            notiz: "",
            telefon: ['', '', '', '', '']
        };

        console.log("newEvent, view: ", this.state.currentView)
        console.log("newEvent, resourceId: ", event.resourceId)
        console.log("newEvent, hour.resourceId: ", hour.status)
        if (this.state.currentView !== "day") {
            hour.status = parseInt(window.prompt("Kolonne: \n 1: Dämmung\n 2: Thermowhite\n 3: Zementestrich 1\n 4: Zementestrich 2\n 5: Fließestrich\n 6: Sonstiges\n 7: Zusatzkolonne, \n 8: Meterriss, \n 9: Finn",)) };

        if (!Number.isInteger(hour.status) || hour.status < 1 || hour.status > 10) {
            // Set hour.status to 6
            hour.status = 6;
        }

        hour.id = await newEventToDB(hour);

        this.setState(prevState => ({
            events: prevState.events.concat([hour])
            /*eventsBackup: prevState.events.concat([hour])*/
        }), () => {
            this.setState({ showPopupForm: true, event: hour });
            events = this.state.events
            //console.log("newEvent state.events:", this.state.events);
            //console.log("newEvent events:", events);
        });
    };


    componentDidMount() {
        // Call handleViewChange with initial date and view

        fetch();
        const initialDate = new Date(); // You can set it to any initial date you want
        const initialView = "day"; // Set to the initial view you want
        this.handleViewChange(initialDate, initialView);
    };

    onView = (view) => {

        //console.log(this.state.meterriss)
        if (this.state.meterriss === true && view === "agenda") {
            //console.log(this.state.events)
            //this.state.eventsBackup = this.state.events
            this.state.events = this.state.events.filter((e) => {
                return (
                    e.resourceId === 8 &&
                    moment(e.start).format('YYYY-MM-DD') === moment(this.state.currentDate).format('YYYY-MM-DD')

                );
            });
            //console.log(this.state.events[0].start)
            //console.log(this.state.events[0].end)

        }
        else {
            this.state.events = this.state.eventsBackup
        }

        this.setState({
            currentView: view,
        });
        //console.log("----------------------------------------onView---------------------------------------->");
        //console.log("aktueller View:", view)
        //console.log("current View:", this.state.currentView)
        //console.log("<----------------------------------------onView----------------------------------------");
    };

    sameDateEvents(date) {
        const sdate = moment(date).format('YYYY-MM-DD');
        //console.log("sdate: ", sdate)

        const eventsWithSameDate = events.filter(event => {
            const eventStartDate = moment(new Date(event.start)).format('YYYY-MM-DD');
            const eventEndDate = moment(new Date(event.end)).format('YYYY-MM-DD');
            //console.log("date: ", eventStartDate)
            // console.log("date: ", eventEndDate)

            // Check if the current date is within the range of event start and end
            return moment(sdate).isBetween(eventStartDate, eventEndDate, '[]', '[]')
                && moment(new Date(event.end)).isAfter(moment(date).format('YYYY-MM-DDT00:00:00.000[Z]'));
        });

        const uniqueEmployees = [...new Set(eventsWithSameDate.flatMap(event => event.mitarbeiter.split("<br>")))];
        const uniqueFahrzeuge = [...new Set(eventsWithSameDate.flatMap(event => event.fahrzeuge.split("<br>")))];

        let officeEmployees = []
        //console.log(moment(date).format('dddd'))
        if (moment(date).format('dddd') === 'Freitag') {
            officeEmployees = [
                "Alt, Matthias",
                "Eiserloh, Siegfried",
                "Ladosz, Stanislaw",
                "Meter-Schu, Heike",
                "Nisius, Sarah",
                "Olbermann, Finn",
                "Olbermann, Lars",
                "Olbermann, Nils",
                "Schulz, Rainer",
                "Steinbach, Fabian",
                "Nilles, Gerd",
                "Wagner, Marcus"
            ];
        }
        else if (moment(date).format('dddd') === 'Dienstag') {
            officeEmployees = [
                "Alt, Matthias",
                "Eiserloh, Siegfried",
                "Ladosz, Stanislaw",
                "Meter-Schu, Heike",
                "Nisius, Sarah",
                "Olbermann, Finn",
                "Olbermann, Lars",
                "Olbermann, Nils",
                "Schulz, Rainer",
                "Wagner, Marcus"
            ];
        }
        else {
            officeEmployees = [
                "Alt, Matthias",
                "Eiserloh, Siegfried",
                "Ladosz, Stanislaw",
                "Meter-Schu, Heike",
                "Nisius, Sarah",
                "Olbermann, Finn",
                "Olbermann, Lars",
                "Olbermann, Nils",
                "Schulz, Rainer",
                "Steinbach, Fabian",
                "Wagner, Marcus"
            ];
        }
        
        const extendedUniqueEmployees = [...uniqueEmployees, ...officeEmployees];

        const normalizedUniqueEmployees = extendedUniqueEmployees.map(name => name.trim());
        const normalizeduniqueFahrzeuge = uniqueFahrzeuge.map(name => name.trim());

        const nonUniqueEmployees = Employees.filter(employee => !normalizedUniqueEmployees.includes(employee.name));
        const nonUniqueFahrzeuge = Fahrzeuge.filter(fahrzeug => !normalizeduniqueFahrzeuge.includes(fahrzeug));

        const eventsByState = [];

        for (let i = 0; i <= 7; i++) {
            const sortedEventsForStatus = eventsWithSameDate
                .filter(event => event.resourceId === i)
                .sort((a, b) => new Date(a.start) - new Date(b.start))
                .map(event => event.ort && `${event.strasse.replace(/ /g, '\+') || ''}+${event.ort.replace(/ /g, '\+').replace(/L-/g, '') || ''}`)
                .join('/');

            // Check if no event is found with status === i, then push an empty string
            if (!sortedEventsForStatus) {
                eventsByState.push('');
            } else {
                eventsByState.push(sortedEventsForStatus);
            }

        }

        for (let i = 8; i <= 8; i++) {
            const sortedEventsForStatus = eventsWithSameDate
                .filter(event => event.resourceId === i && event.mitarbeiter === "Alt, Matthias")
                .sort((a, b) => new Date(a.start) - new Date(b.start))
                .map(event => `${event.strasse.replace(/ /g, '\+') || ''}+${event.ort.replace(/ /g, '\+').replace(/L-/g, '') || ''}`)
                .join('/');

            // Check if no event is found with status === i, then push an empty string
            if (!sortedEventsForStatus) {
                eventsByState.push('');
            } else {
                eventsByState.push(sortedEventsForStatus);
            }

        }

        for (let i = 8; i <= 8; i++) {
            const sortedEventsForStatus = eventsWithSameDate
                .filter(event => event.resourceId === i && event.mitarbeiter != "Alt, Matthias")
                .sort((a, b) => new Date(a.start) - new Date(b.start))
                .map(event => `${event.strasse.replace(/ /g, '\+') || ''}+${event.ort.replace(/ /g, '\+').replace(/L-/g, '') || ''}`)
                .join('/');

            // Check if no event is found with status === i, then push an empty string
            if (!sortedEventsForStatus) {
                eventsByState.push('');
            } else {
                eventsByState.push(sortedEventsForStatus);
            }

        }

        for (let i = 10; i <= 10; i++) {
            const sortedEventsForStatus = eventsWithSameDate
                .filter(event => event.resourceId === i)
                .sort((a, b) => new Date(a.start) - new Date(b.start))
                .map(event => event.ort && `${event.strasse.replace(/ /g, '\+') || ''}+${event.ort.replace(/ /g, '\+').replace(/L-/g, '') || ''}`)
                .join('/');

            // Check if no event is found with status === i, then push an empty string
            if (!sortedEventsForStatus) {
                eventsByState.push('');
            } else {
                eventsByState.push(sortedEventsForStatus);
            }

        }

        //console.log('eventsByState :', eventsByState)

        this.setState({
            currentEvents: eventsWithSameDate,
            uniqueEmployees: normalizedUniqueEmployees,
            nonUniqueEmployees: nonUniqueEmployees,
            verfFahrzeuge: nonUniqueFahrzeuge,
            mapsEvents: eventsByState
        });



        return eventsWithSameDate;
    }


    handleViewChange = (date, view) => { // Add this function to handle view changes
        //console.log("date: ", date)

        this.sameDateEvents(date)

        this.setState({
            //currentView: view,
            currentDate: date,
        });

        if (this.state.meterriss === true && view === "agenda") {
            //console.log(this.state.events)
            //this.state.eventsBackup = this.state.events
            this.state.events = this.state.events.filter((e) => {
                return (
                    e.resourceId === 8 &&
                    moment(e.start).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')

                );
            });
            console.log(this.state.events)
            console.log(this.state.currentDate)
            console.log(date)
            //console.log(this.state.events[0].start)
            //console.log(this.state.events[0].end)

        }
        else {
            this.state.events = this.state.eventsBackup
        }

        //console.log(this.state.events)
        //console.log(this.state.eventsBackup)
        //console.log("----------------------------------------handleViewChange---------------------------------------->");
        //console.log("aktueller Tag:", date);
        //console.log("aktueller View:", view);
        //console.log("current View:", this.state.currentView);
        //console.log("Events: ", events);

        ////console.log("uniqueEmployees: ", normalizedUniqueEmployees);
        ////console.log("Employees: ", Employees);
        ////console.log("verfügbare Mitarbeiter", nonUniqueEmployees)
        //console.log("<----------------------------------------handleViewChange----------------------------------------");
        //console.log("Left Employees: ", Employees.filter(employee => !uniqueEmployees.includes(employee)));
    };

    getWorkweeksForMonth = (date) => {
        const firstDayOfMonth = moment(date).startOf('month');
        const lastDayOfMonth = moment(date).endOf('month');
        const workweeks = [];

        workweeks.push(`${firstDayOfMonth.isoWeek()} - ${lastDayOfMonth.isoWeek()}`);

        return workweeks;
    };

    bearbeiten = () => {
        if (!bearbeiten) { window.alert("Bearbeiten aktiviert. \nWehe du vermurkst was ;)") }
        //else { window.alert("Bearbeiten deaktiviert") }
        bearbeiten = !bearbeiten
        this.onView(this.state.currentView)
    };


    MyCustomHeader = ({ label, onNavigate, onView, view }) => {
        const shouldDisplayWeek = view === 'week' || view === 'day';
        return (
            <div className="rbc-toolbar print-hide">
                <button
                    type="button"
                    className="rbc-btn-group"
                    onClick={() => onNavigate(Navigate.PREVIOUS)}
                >
                    Zurück
                </button>
                <button
                    type="button"
                    className="rbc-btn-group"
                    onClick={() => onNavigate(Navigate.TODAY)}
                >
                    Heute
                </button>
                <button
                    type="button"
                    className="rbc-btn-group"
                    onClick={() => onNavigate(Navigate.NEXT)}
                >
                    Vor
                </button>
                <div style={{ alignItems: 'center' }}>
                    <label htmlFor="datePicker" style={{ marginRight: '20px' }}> </label>
                    <input
                        type="date"
                        id="datePicker"
                        value={moment(this.state.currentDate).format('YYYY-MM-DD')}
                        onChange={(e) => this.handleDateChange(new Date(e.target.value))}
                    />
                </div><div className="rbc-toolbar-label" style={{ textAlign: 'center' }}>
                    {label} {shouldDisplayWeek && `(KW ${moment(this.state.currentDate).isoWeek()})`}{!shouldDisplayWeek && `(KW ${this.getWorkweeksForMonth(this.state.currentDate).join(', ')})`}
                </div>

                {/*{console.log("label: ", label)}*/}
                {bearbeiten && (
                    <>
                        <button
                            type="button"
                            className="rbc-btn-group"
                            onClick={() => this.bearbeiten()}
                            style={{ backgroundColor: 'lightcoral', color: 'white'}}  
                        >
                            Bearbeiten
                        </button>
                    </>
                )}
                {!bearbeiten && (
                    <>
                        <button
                            type="button"
                            className="rbc-btn-group"
                            onClick={() => this.bearbeiten()}
                        >
                            Bearbeiten
                        </button>
                    </>
                )}
                
                <button
                    type="button"
                    className="rbc-btn-group"
                    onClick={() => {
                        onView('month');
                        this.setState({ meterriss: false });
                    }} 
                >
                    Monat
                </button>
                <button
                    type="button"
                    className="rbc-btn-group"
                    onClick={() => {
                        onView('week');
                        this.setState({ meterriss: false });
                    }} 
                >
                    Woche
                </button>
                <button
                    type="button"
                    className="rbc-btn-group"
                    onClick={() => {
                        onView('day');
                        this.setState({ meterriss: false });
                    }} 
                >
                    Tag
                </button>
                <button
                    type="button"
                    className="rbc-btn-group"
                    onClick={() => {
                        this.setState({meterriss: false});
                        onView('agenda');
                    }}
                >
                    Liste
                </button>
                <button
                    type="button"
                    className="rbc-btn-group"
                    onClick={() => {
                        if (this.state.meterriss === true && this.state.currentView === "day") {
                            onView('agenda');
                            this.setState({ meterriss: true });
                            

                        }
                        else if (this.state.meterriss === true && this.state.currentView === "agenda") {
                            onView('day');
                            this.setState({ meterriss: true });

                        }
                        else {
                            onView('day');
                            this.setState({ meterriss: true });

                        }
                    }}
                >
                    Meterriss
                </button>                
            </div >
        )
    };

    render() {
        const { showPopupForm, nonUniqueEmployees, verfFahrzeuge, currentDate, isSearchModalOpen, searchQuery, searchResults } = this.state;

        //console.log("nonUniqueEmployees", nonUniqueEmployees.map(employee => employee.name));
        return (
            <div
                style={{
                    height: "93%",
                    position: "relative", // Make the container position relative
                }}
             >
                    {/* Logo */}
                <div
                    className="print-hide" 
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                            background: `url('https://estrich-meter.de/wp-content/uploads/2024/01/logo2.png') no-repeat center center fixed`,
                        backgroundSize: "contain",
                        zIndex: -1,
                    }}
                />

                    {/* Calendar */}
                <DragAndDropCalendar
                    selectable
                    localizer={localizer}
                    events={this.state.events}
                    onEventDrop={bearbeiten ? this.moveEvent : undefined}
                    onDropFromOutside={bearbeiten ? this.onDropFromOutside : undefined}
                    onDragOver={this.customOnDragOver}
                    onDoubleClickEvent={this.handleSelectEvent}  // Conditionally set onSelectEvent
                    components={{
                        event: (props) => <CustomEvent {...props} currentView={this.state.currentView} meterriss={this.state.meterriss} />,
                        toolbar: this.MyCustomHeader,
                    }}
                    resizable
                    onEventResize={bearbeiten ? this.resizeEvent : undefined}
                    onSelectSlot={bearbeiten ? this.newEvent : undefined}
                    defaultView={this.state.currentView}
                    employees={Employees}
                    currentView={this.state.currentView}
                    messages={{
                        month: 'Monat',
                        day: 'Tag',
                        today: 'Heute',
                        week: 'Woche',
                        next: 'Vor',
                        previous: 'Zurück',
                        agenda: 'Liste',
                        date: 'Datum',
                        time: 'Uhrzeit',
                        event: 'Termin',
                        allDay: '',
                        noEventsInRange: 'Keine Termine',
                        showMore: (total) => `+${total} weitere`,
                    }}
                    
                    popup
                    eventPropGetter={this.eventStyleGetter} // Add this prop to apply custom styles
                    resources={this.state.currentView !== "week" ? this.myresources(this.state.currentDate) : undefined}
                    
                    onNavigate={this.handleViewChange}
                    onView={this.onView}
                    tooltipAccessor={customTooltipAccessor}

                    max={moment("2022-10-10T18:00:00").toDate()}
                    min={moment("2022-10-10T06:00:00").toDate()}
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}  // Adjust the alpha value (0.8) as needed
                    date={currentDate}
                />

                     {/*Container for Date Selector and Available Employees */}
                
                <div style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                    <h3 className="print-hide" style={{ marginRight: '10px' }}>Verfügbar:</h3>

                    {nonUniqueEmployees.map((employee) => (
                        <div key={employee.name} style={{ marginRight: '25px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div className="print-hide">{employee.name.split(',').slice(1).join(',')}</div>
                            <div className="print-hide">{employee.name.split(',')[0]}</div>
                        </div>
                    ))}
                </div>

                     {/*Render the SearchPopup component as a modal */}
                {isSearchModalOpen && (
                    <SearchPopup
                        searchQuery={searchQuery}
                        searchResults={searchResults}
                        onClose={this.closeSearchModal}
                        onSearch={this.handleSearch}
                        handleDateChange={this.handleDateChange}
                    />
                )}

                {showPopupForm && (
                    <>
                        <div
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100vw',
                                height: '100vh',
                                background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
                                zIndex: 999, // Ensure the overlay is on top
                            }}
                        />
                        <PopupForm projects={Projects} employees={Employees} verfMitarbeiter={nonUniqueEmployees} fahrzeuge={Fahrzeuge} verfFahrzeuge={verfFahrzeuge} event={this.state.event} onClose={this.onClose} bearbeiten={bearbeiten} setBearbeiten={this.bearbeiten} />
                    </>
                )}

            </div>
        );
    }
}


const CustomEvent = ({ event, currentView, meterriss } ) => {


    //console.log("----------------------------------------CustomEvent---------------------------------------->");
    //console.log("currentView:", currentView);
    //console.log("event:", event);
    //console.log("<----------------------------------------CustomEvent----------------------------------------");
    let link = "";

    if (event.resourceId === 2) {
        link = "https://www.google.de/maps/dir/Europa-Allee+38,+54343+Foehren/"
    }
    else {
        link = "https://www.google.de/maps/dir/Kirchstra%C3%9Fe+20,+54426+Heidenburg/"
    }

    if (currentView == "day" || currentView == "week") {
        const isAllDay = moment(event.start).isSame(moment(event.start).startOf('day'));
        //console.log("event.telefon: ", event.telefon)
        if (isAllDay) {
            return (
                <div style={{ fontSize: '15px' }}>
                    <strong>{event.title}</strong>
                </div>
            );
        }
        else {
            if (meterriss == false) {
                return (
                    <div style={{ fontSize: '15px' }}>
                        <div style={{ fontSize: '12px' }}>{moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}: </div> <br />
                        <strong>{event.title}{':   '}{event.bauherr}</strong>
                        <p>
                            <a href={`${link}${encodeURIComponent(`${event.strasse.replace(' ', '\+')}+${event.ort.replace(' ', '\+')}`)}/am=t/`} target="_blank" rel="noopener noreferrer" className="linkText" style={getLinkStyles(event.resourceId)} >
                                {event.ort}<br />{event.strasse}
                            </a>
                        </p>
                        <p dangerouslySetInnerHTML={{ __html: event.mitarbeiter }} />
                        <p dangerouslySetInnerHTML={{ __html: event.fahrzeuge }} />
                        <p dangerouslySetInnerHTML={{ __html: event.notiz }} />
                        <p>
                            <a href={`tel:${event.telefon[0]}`} style={getLinkStyles(event.resourceId)}>{event.telefon[4]}</a> <br />
                            <a href={`tel:${event.telefon[0]}`} style={getLinkStyles(event.resourceId)}>{event.telefon[0]}</a> <br />
                            <a href={`tel:${event.telefon[2]}`} style={getLinkStyles(event.resourceId)}>{event.telefon[2]}</a>
                        </p>
                    </div>
                );
            }
            else {
                return (
                    <div className="meterriss" style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                        <strong>{event.title}: {event.bauherr}</strong>
                        <div>
                            <a href={`${link}${encodeURIComponent(`${event.strasse.replace(' ', '+')}+${event.ort.replace(' ', '+')}`)}/am=t/`} target="_blank" rel="noopener noreferrer" className="linkText" style={getLinkStyles(event.resourceId)}>
                                {event.ort}<br />{event.strasse}
                            </a>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: event.mitarbeiter }} />
                        <p dangerouslySetInnerHTML={{ __html: event.fahrzeuge }} />
                        <div dangerouslySetInnerHTML={{ __html: event.notiz }} />
                        <div>
                            <a href={`tel:${event.telefon[0]}`} style={getLinkStyles(event.resourceId)}>{event.telefon[4]}</a> <br />
                            <a href={`tel:${event.telefon[0]}`} style={getLinkStyles(event.resourceId)}>{event.telefon[0]}</a> <br />
                            <a href={`tel:${event.telefon[2]}`} style={getLinkStyles(event.resourceId)}>{event.telefon[2]}</a>
                        </div>
                    </div>
                );


            }
        }

    }
    else if (currentView == "month") {
        return (
            <div>
                <strong>{event.title}{':   '}{event.bauherr}</strong>

            </div>
        );
    }
    // Default rendering for other views
    return (
        <div className="meterriss" style={{
            display: 'grid',
            gridTemplateColumns: '3fr 2fr 1fr', // Adjust these ratios according to your preference
            alignItems: 'start',
            gap: '20px' // Adjust the gap to manage spacing between columns
        }}>
            <div>
                <strong>{event.title}: {event.bauherr}</strong> <br />
                {statusMapping[event.status] }
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                <a href={`${link}${encodeURIComponent(`${event.strasse.replace(' ', '+')}+${event.ort.replace(' ', '+')}`)}/am=t/`} target="_blank" rel="noopener noreferrer" className="linkText" style={getLinkStyles(event.resourceId)}>
                    {event.ort}<br />{event.strasse}
                </a>
            </div>
            <div>
                <div dangerouslySetInnerHTML={{ __html: event.mitarbeiter }} />
                <p dangerouslySetInnerHTML={{ __html: event.fahrzeuge }} />
                <div dangerouslySetInnerHTML={{ __html: event.notiz }} />
            </div>
            <div style={{ gridColumn: '1 / -1' }}> {/* This makes the contact info span all columns */}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <a href={`tel:${event.telefon[0]}`} style={getLinkStyles(event.resourceId)}>{event.telefon[4]}</a> <br />
                    <a href={`tel:${event.telefon[0]}`} style={getLinkStyles(event.resourceId)}>{event.telefon[0]}</a> <br />
                    <a href={`tel:${event.telefon[2]}`} style={getLinkStyles(event.resourceId)}>{event.telefon[2]}</a>
                </div>
            </div>
        </div>

    );
    
};

const getLinkStyles = (resourceId) => {
    let color = 'white';
    let textDecoration = 'none';
    switch (resourceId) {
        case 1:
            color = 'black';
            break;
        case 2:
            color = 'black';
            break;
        case 3:
            color = 'black';
            break;
        case 4:
            color = 'black';
            break;
        case 5:
            break;
        case 7:
            break;
        case 8:
            break;
        case 6:
            break;
        case 10:
            break;
        default:
            break;
    }

    return {
        color: color,
        textDecoration: textDecoration
    };
};

const statusMapping = {
    1: "Dämmung",
    2: "Thermowhite",
    3: "Zementestrich 1",
    4: "Zementestrich 2",
    5: "Fließestrich",
    6: "Sonstiges",
    7: "Zusatzkolonne",
    8: "Meterriss",
    10: "Finn",
};

const customTooltipAccessor = (event) => {

    const mitarbeiterWithNewlines = event.mitarbeiter.replace(/<br\s*\/?>/g, '\n');
    const fahrzeugeWithNewlines = event.fahrzeuge.replace(/<br\s*\/?>/g, '\n');
    const notizWithNewlines = event.notiz.replace(/<br\s*\/?>/g, '\n');
    const statusWord = statusMapping[event.status];
    return `Kolonne: ${statusWord}\n${event.title}: ${event.bauherr}\n ${event.ort} ${event.strasse}\n ${mitarbeiterWithNewlines}\n ${fahrzeugeWithNewlines}\n ${notizWithNewlines}\n ${event.telefon[4]}\n ${event.telefon[0]}\n ${event.telefon[2]}`;
};

export default Dnd;