// SearchPopup.tsx

import React, { useState, useEffect } from 'react';
import moment from "moment";


const SearchPopup = ({ searchQuery, searchResults, onClose, onSearch, handleDateChange }) => {
    const [query, setQuery] = useState(searchQuery);

    const handleSearchChange = (event) => {
        setQuery(event.target.value);
    };

    const handleSearch = async () => {
        const inputField = document.querySelector('input[type="text"]');

        if (inputField) {
            // Execute onSearch
            await onSearch(query);

            // Scroll to the input field if it is not at the top
            if (inputField.getBoundingClientRect().top > 0) {
                inputField.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    const handleResultClick = (result) => {
        // Call the provided onUpdateCurrentDate function with the clicked result's start date
        handleDateChange(result.start);
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // You can use 'auto' or 'smooth'
        });
    };

    const handleKeyDown = (event) => {
        // Trigger search when Enter key is pressed
        if (event.key === 'Enter') {
            handleSearch();
            // Scroll to the bottom of the page

        }
    };


    // Sort the search results by result.start
    const sortedResults = [...searchResults].sort((b, a) => moment(a.start) - moment(b.start));

    const statusMap = {
        1: { title: "Dämmung ", backgroundColor: '#ff0000' },
        2: { title: "Thermowhite" },
        3: { title: "Zementestrich 1" },
        4: { title: "Zementestrich 2" },
        5: { title: "Fließestrich" },
        7: { title: "Zusatzkolonne" },
        8: { title: "Meterriss" },
        6: { title: "Sonstiges" }
    };


    return (
        <div className="search-popup print-hide">

            {/* Display search input next to the heading */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3 style={{ marginRight: '10px' }}>Suche:</h3>
                <input
                    type="text"
                    value={query}
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyDown} // Handle Enter key press
                />
            </div>

            {/* Display sorted search results */}
            {sortedResults.length > 0 && (
                < table className="blackText">
                <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Kolonne</th>
                        <th>Projekt</th>
                        <th>Bauvorhaben</th>
                        <th>Notiz</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedResults.map((result) => (
                        <tr key={result.id} onClick={() => handleResultClick(result)}>
                            <td>{moment(result.start).format('DD.MM.YYYY')}{'\u00A0\u00A0'}</td>
                            <td>{statusMap[result.status].title}</td>
                            <td style={{ fontWeight: 'bold' }}>{'\u00A0\u00A0'}{result.title}{'\u00A0\u00A0'}</td>
                            <td>{result.bauherr}, {result.strasse}, {result.ort}</td>
                            <td>{'\u00A0\u00A0\u00A0'}{result.notiz.replace(/<br><br>/g, ', \u00A0').replace(/<br>/g, ', \u00A0')}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            )}


            {/* Search button */}
            {/*<button onClick={handleSearch}>Suchen</button>*/}

            {/* Close button */}
            {/*<button onClick={onClose}>Schließen</button>*/}
        </div>
    );
};

export default SearchPopup;
